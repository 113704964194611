import { observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import { useMemo } from 'react';

useStaticRendering(typeof window === 'undefined');

import AppStore from '@app/stores/AppStore';
import AuthStore from '@app/stores/AuthStore';

export type RootStoreType = {
  appStore: AppStore;
  authStore: AuthStore;
};

let store: RootStore | null = null;

class RootStore {
  @observable public appStore: AppStore;
  @observable public authStore: AuthStore;

  constructor() {
    this.appStore = new AppStore(this);
    this.authStore = new AuthStore(this);
  }

  hydrate = (initialState: RootStoreType) => {
    if (!initialState) return;

    this.appStore = initialState.appStore ?? new AppStore(this);
    this.authStore = initialState.authStore ?? new AuthStore(this);
  };
}

export function initializeStore(initialState: RootStoreType | null = null): RootStore {
  const _store = store ?? new RootStore();

  if (initialState) {
    _store.hydrate(initialState);
  }

  if (typeof window === 'undefined') return _store;

  if (!store) store = _store;

  return _store;
}

export function useStore(initialState: RootStoreType): RootStore {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
