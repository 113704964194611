import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`  
  body {
    background-color: ${(props) => props.theme.colors.background};
    font-family: 'Roboto';
    font-weight: normal;
    user-select: none;
  }

  @media (max-width: 576px) { 
    text-align: center;
    justify-content: center; 
  }

  .global_select {
    padding: 4px 6px;
    background: #ffffff;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    .global_select {
      font-size: 16px !important;
    }
  }
  
  .msg_modal{
    max-width: 380px;
    padding: 40px 20px;
  }

  @media only screen and (max-width: 569px) {
    .global_select {
      max-width: 260px;
    }
  }

  .page-link {
    color: ${(props) => props.theme.colors.secundary} !important;
  }

  .page-item.active .page-link {
    color: ${(props) => props.theme.colors.secundary};
    background-color:  ${(props) => props.theme.colors.primary};
    border-color:  ${(props) => props.theme.colors.primary};
  }

  .whatsapp-ico{
    fill: white;
    width: 50px;
    height: 50px;
    padding: 3px;
    background-color: #4dc247;
    border-radius: 50%;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
    /* box-shadow: 2px 2px 11px rgba(0,0,0,0.7); */
    position: fixed;
    bottom: 20px;
    right : 20px;
    z-index: 10;
  }
  
  .slick-arrow {
    margin: 0 20px !important;
  }
`;

export default GlobalStyle;
