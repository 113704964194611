import React from 'react';
import Honeybadger from '@honeybadger-io/js';
import { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';

import AppProvider from '@app/hooks/AppProvider';
import GlobalStyle from '@app/styles/global';
import Theme from '@app/styles/theme';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
config.autoAddCss = false;

import 'bootstrap/dist/css/bootstrap.min.css';
import WhatsappIcon from '@app/components/Icons/WhatsappIcon';

// https://docs.honeybadger.io/lib/javascript/reference/configuration.html
Honeybadger.configure({
  apiKey: process.env.NEXT_PUBLIC_HONEYBADGER_API_KEY,
  // revision: process.env.HONEYBADGER_REVISION,
  environment: process.env.NEXT_PUBLIC_HONEYBADGER_ENVIRONMENT,
  projectRoot: 'webpack://_N_E/./',
  reportData: process.env.NODE_ENV === 'production',
  ...(process.env.HEROKU_SLUG_COMMIT && { revision: process.env.HEROKU_SLUG_COMMIT }),
});

// This is handy for testing; remove it in production.
if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_HONEYBADGER_ENVIRONMENT === 'staging') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.Honeybadger = Honeybadger;
}

const App = (props: AppProps): React.ReactElement => {
  const { Component, pageProps } = props;

  // Workaround for https://github.com/zeit/next.js/issues/8592
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const err = (props as any).err;
  const modifiedPageProps = { ...pageProps, err };

  return (
    <AppProvider initialState={pageProps.initialState}>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Component {...modifiedPageProps} />
        <a
          href="https://api.whatsapp.com/send?phone=+5562982962240&text=Gostaria%20de%20solicitar%20sua%20ajuda%20na%20plataforma."
          className="float"
          target="_blank"
          rel="noreferrer"
        >
          <WhatsappIcon></WhatsappIcon>
        </a>
      </ThemeProvider>
    </AppProvider>
  );
};

export default App;
