import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import Cookie from 'js-cookie';
import { User } from '@app/types/user';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (message?.toLowerCase()?.match(/token inválido/)) {
        Cookie.remove('user');
      }
      return { message, locations, path };
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError.message}`);
  }
});

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
  credentials: 'same-origin',
});

const retryLink = new RetryLink({
  delay: {
    initial: 600,
    jitter: false,
  },
  attempts: {
    max: 10,
  },
});

const authLink = setContext((_, { headers }) => {
  let user: User | null = null;
  const cookie: string | undefined = Cookie.get('user');

  if (cookie) {
    user = JSON.parse(cookie);
  }

  return {
    headers: {
      ...headers,
      Authorization: user?.token ? `Bearer ${user.token}` : '',
    },
  };
});

const cache = new InMemoryCache();

export default new ApolloClient({
  link: ApolloLink.from([retryLink, errorLink, authLink.concat(httpLink)]),
  cache,
});
