import { DefaultTheme } from 'styled-components';

export const Theme = (): DefaultTheme => {
  return {
    title: 'light',
    colors: {
      primary: '#C79852',
      secundary: '#000000',
      background: '#F8F8F8',
    },
  };
};

export default Theme;
