import { Athlete } from '@app/types/Athlete';
import { observable, action } from 'mobx';
import { RootStoreType } from '@app/stores/RootStore';
import { User } from '@app/types/user';

class AuthStore {
  @observable private rootStore: RootStoreType;
  @observable public isAuthenticate = false;
  @observable public user: User | null = null;

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
  }

  @action
  public setAuthenticate = (value: boolean): void => {
    this.isAuthenticate = value;
  };

  @action
  public setUser = (value: User | null): void => {
    this.user = value;
  };

  public filterAthlete = (id: number): Athlete => {
    let athlete: Athlete;

    switch (this.user?.subject.__typename) {
      case 'Staff':
        athlete = this.user?.subject.club.athletes.nodes.find((athlete: Athlete) => Number(athlete.id) === id);
        break;
      default:
        athlete = this.user?.subject.athletes.find((athlete: Athlete) => Number(athlete.id) === id);
        break;
    }

    return athlete;
  };
}

export default AuthStore;
