import { ApolloClient } from '@apollo/client';
import { observable, action } from 'mobx';
import { RootStoreType } from '@app/stores/RootStore';
import GraphqlClient from '@app/graphql/client';

type StoreProperties = {
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphqlClient: ApolloClient<any>;
};

type StorePublicInterface = {
  setLoading: (value: boolean) => void;
};

type FilterSearch = {
  filter: string;
  filterPosition: string;
};

export type AppStoreType = StoreProperties & StorePublicInterface;

class AppStore {
  @observable private readonly rootStore: RootStoreType;
  @observable public isLoading = false;
  @observable public isMenu = false;
  @observable public isError = false;
  @observable public isBackPage = false;

  @observable public filterPageSearch: FilterSearch | null = null;

  public graphqlClient = GraphqlClient;

  constructor(rootStore: RootStoreType) {
    this.rootStore = rootStore;
  }

  @action
  public setLoading = (value: boolean): void => {
    this.isLoading = value;
  };

  @action
  public setIsMenu = (value: boolean): void => {
    this.isMenu = value;
  };

  @action
  public setIsError = (value: boolean): void => {
    this.isError = value;
  };

  @action
  public setIsBackPage = (value: boolean): void => {
    this.isBackPage = value;
  };

  @action
  public setFilterPageSearch = (value: FilterSearch | null): void => {
    this.filterPageSearch = value;
  };
}

export default AppStore;
