import React from 'react';

import Context from '@app/hooks/context';
import { RootStoreType, useStore } from '@app/stores/RootStore';
import { LanguageProvider } from './LanguageProvider';

type Props = {
  children: React.ReactNode;
  initialState: RootStoreType;
};

const AppProvider = ({ children, initialState }: Props): JSX.Element => {
  const rootStore = useStore(initialState);

  return (
    <LanguageProvider>
      <Context.Provider value={rootStore}>{children}</Context.Provider>
    </LanguageProvider>
  );
};

export default AppProvider;
